<template>
    <div class="payment-method"  v-if="paymentMethods.length">
        <span class="color-grey-100 font-18 font-700 font-nunito">Metode Pembayaran : </span>
        <div class="payment-method__list mt-3 d-flex flex-wrap gap-3">
            <button class="button button--sm button--outline-primary font-16" 
            :class="{'active': payment.id === selectedPaymentMethodType.id}" 
            v-for="payment in sortedPaymentMethods" 
            :key="payment.id"
            @click="SET_SELECTED_PAYMENT_METHOD_TYPE(payment)"
            >{{payment.name}}</button>
        </div>
        <payment-account v-if="selectedPaymentMethodType.name.toLowerCase() === 'edc' || selectedPaymentMethodType.name.toLowerCase() === 'bank transfer' || selectedPaymentMethodType.name.toLowerCase() === 'transfer bank'" />
        <image-input class="border-top pt-4 mt-4" ref="imageInput" @uploadImage="getImage" v-if="selectedPaymentMethodType.payment_proof === 'required' || selectedPaymentMethodType.payment_proof === 'optional' " :optional="selectedPaymentMethodType.payment_proof === 'optional'" />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations} from 'vuex'

import ImageInput from '@/components/base/ImageInput.vue'
import PaymentAccount from '@/components/payment/partials/PaymentAccount.vue'
export default {
  components: { PaymentAccount, ImageInput },
  watch : {
    selectedPaymentMethodType () {
        this.SET_SELECTED_PAYMENT_IMAGE_PROOF('')
        if(Object.hasOwnProperty.call(this.$refs ,'imageInput')) {
            if(this.$refs.imageInput) {
                this.$refs.imageInput.clearImage()
            }
           
        }
    },
  },
  computed : {
      ...mapState('payment', ['paymentMethods', 'selectedPaymentMethodType']),
      sortedPaymentMethods() {
      const sortingOrder = ['Tunai', 'EDC', 'Transfer Bank', 'QRIS', 'Split Payment'];
      // Create a copy of the payment methods array for sorting
      const sortedMethods = [...this.paymentMethods, { name: 'Split Payment' , id: 'split', payment_proof: '', payment_methods: []}];

      // Custom sorting function
      sortedMethods.sort((a, b) => {
        const indexA = sortingOrder.indexOf(a.name);
        const indexB = sortingOrder.indexOf(b.name);

        // Handle cases where the name is not in the sorting order
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });

      return sortedMethods;
    },
  },
  methods : {
      ...mapActions('payment', ['getPaymentMethodList']),
      ...mapMutations('payment', ['SET_SELECTED_PAYMENT_METHOD_TYPE', 'SET_SELECTED_PAYMENT_IMAGE_PROOF']),
      getImage(img) {
        this.SET_SELECTED_PAYMENT_IMAGE_PROOF(img)
      }
  },
  mounted() {
      this.getPaymentMethodList()
  }

}
</script>

<style lang="scss" scoped>
    .payment-method {
        width: 320px;
    }
</style>