<template>
  <div class="page-wrapper bg-white" :class="{'onSidebarOpen' : sidebarVisible}">
    <payment-content />
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import PaymentContent from '@/components/payment/PaymentContent.vue';

export default {
  components: {  PaymentContent },
    computed: {
        ...mapState('home', ['sidebarVisible']),
    },
      methods : {
       ...mapMutations('home', ['setSidebarVisible']),
    },
    created() {
      this.setSidebarVisible(false)
    }
};
</script>

<style lang="scss" scoped>
  .page-wrapper {
    background: #F5F5F5;
    transition: margin 400ms ease-in-out;
    overflow-x: hidden;
    position: relative;
    &.onSidebarOpen {
      margin-left: 78px;
    }
  }
</style>

