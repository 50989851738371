<template>
    <div v-if="selectedPaymentMethodType.need_nominal">
        <span class=" font-nunito">{{ selectedPaymentMethodType.name.toLowerCase() === 'edc'
            ?
            'No Kartu' : 'Bayar' }}</span>
        <div class="payment-value">
            <el-input class="mt-2" v-model="inputValue" readonly />
            <div class="d-flex flex-column gap-3 mt-3" v-if="selectedPaymentMethodType.name.toLowerCase() === 'tunai' && showReturn">
                <span class=" font-nunito">Kembalian</span>
                <span class="color-black font-400 font-nunito font-24"
                    v-if="usePoint ? integerValueInput > (finalPrice - calculatedPoint) : integerValueInput > finalPrice">{{
                        usePoint
                        ? formatRupiah(integerValueInput - (finalPrice - calculatedPoint), 'Rp') :
                        formatRupiah(integerValueInput - finalPrice, 'Rp') }}</span>
                <span class="color-black font-400 font-nunito font-24" v-else>{{ formatRupiah(0, 'Rp') }}</span>
            </div>
        </div>
        <numericpad @setValue="setPaymentValue" ref="numericpad" />
    </div>
    
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import { mapState, mapGetters, mapMutations } from 'vuex'

import Numericpad from '@/components/base/Numericpad.vue'
export default {
    mixins: [IhsMixin],
    components: { Numericpad },

    props: {
        selectedPaymentMethodType: {
            type: Object,
            default: () => ({})
        },
        inputData: {
            type: String,
            default: ''
        },
        showReturn: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            input: ''
        }
    },
    watch: {
        selectedPaymentMethodType(value) {
            this.inputValue = '0'
            if (this.$refs.numericpad) {
                this.$refs.numericpad.onReset()
            }
            if (value.name.toLowerCase() === 'edc') {
                this.$emit('padClicked', this.inputData.replace(/\D/g, ""))
            } else {
                this.$emit('padClicked', this.formatRupiah(this.inputData, 'Rp '))
            }
        },
    },
    computed: {
        ...mapState('user', ['calculatedPoint']),
        ...mapState('payment', [  'usePoint']),
        ...mapGetters('cart', ['finalPrice']),
        integerValueInput() {
            return parseInt(this.inputData.replace(/\D/g, ""))
        },
        inputValue: {
            get() {
                return this.inputData
            },
            set(value) {
                this.input = value
                this.$emit('padClicked', value)
            }
        }
    },
    methods: {
        ...mapMutations('payment', ['SET_INPUT_DATA', 'SET_SELECTED_PAYMENT_IMAGE_PROOF']),
        setPaymentValue(value) {
            if (value.length <= 16) {

                if (this.selectedPaymentMethodType.name.toLowerCase() === 'edc') {
                    this.$emit('padClicked', value.replace(/\D/g, ""))
                } else {
                    this.$emit('padClicked',this.formatRupiah(value, 'Rp '))
                }
            }

        }
    }
}
</script>

<style lang="scss">
.payment-value {
    margin-bottom: 30px;

    .el-input__inner {
        padding: 30px 12px;
        border: 1px solid #DCDFE6 !important;
        font-size: 24px;

        &::placeholder {
            color: black;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: black;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: black;
        }
    }
 }
</style>